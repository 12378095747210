import i18n from 'i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Api from '../../util/api';
import AuthApi from '../../util/authApi';
import { clearAdPostRedux } from './../../actions/adpost.actions';
import { isNewChat, isNewNotification, setChatCount } from './../../actions/chatnoti.actions';
import { clearLocationRedux } from './../../actions/location.actions';
import chat from './../../assets/images/chat.svg';
import following from './../../assets/images/following.svg';
import menu from './../../assets/images/menu.svg';
import plus from './../../assets/images/plus.svg';
import saved from './../../assets/images/saved.svg';
import customFunctions from './../../util/customFunctions';

class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'home',
            chatCount: 0,
        };
    }

    componentDidMount = async () => {
        let Url_Segment = window.location.pathname.split('/');
        this.setState({ selectedTab: Url_Segment && Url_Segment.length ? Url_Segment[1] : '' });

        setTimeout(async () => {
            await this.getChatCount();
        }, 30000);
        await this.getChatCount();
        isNewChat(false);
        setInterval(async () => {
            await this.getChatCount();
            isNewChat(false);
        }, 180000);
    };

    getChatCount = async () => {
        const { setChatCountData } = this.props;
        const userId = customFunctions.getUserId();
        if (!userId) {
            this.setState({ chatCount: 0 });
            return;
        }

        const { data } = await AuthApi.getDataFromServer(Api.chatCountUrl);
        if (!data) {
            this.setState({ chatCount: 0 });
            return;
        }
        setChatCountData(data.data);
        this.setState({
            chatCount: parseInt(data.data.total_new) || 0,
        });
    };

    redirectTo = path => {
        if (path) {
            this.props.history.push(path);
        }
    };

    componentDidUpdate = () => {
        this.getNewChatNoti();
    };

    getNewChatNoti = async () => {
        const { chat, notification } = this.props;
        if (chat || notification) {
            const { isNewChat, isNewNotification } = this.props;
            this.setState({ chatCount: 0 }, () => {
                isNewChat(false);
                isNewNotification(false);
                this.getChatCount();
                this.forceUpdate();
            });
        }
    };

    adPostRedirect = async e => {
        e.preventDefault();
        const { clearAdsPostRedux, clearLocationsRedux } = this.props;
        await clearAdsPostRedux();
        await clearLocationsRedux();
        this.setState({ selectedTab: 'ad-post' });
        await customFunctions.clearAdPostlocalStorage();
        this.redirectTo('/ad-post');
    };

    render() {
        const { selectedTab, chatCount } = this.state;

        return (
            <footer className="fixed-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-menu">
                                <ul className="d-flex justify-content-between align-items-center no-style">
                                    <li
                                        className={
                                            selectedTab === 'home' ? 'footer-menu-list active' : 'footer-menu-list'
                                        }
                                    >
                                        <div className="footer-menu-img">
                                            <img src={menu} className="svg" alt="Home" />
                                        </div>
                                        <span>{i18n.t('home')}</span>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="/"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ selectedTab: 'home' });
                                                this.redirectTo('/home');
                                            }}
                                        ></a>
                                    </li>
                                    <li
                                        className={
                                            selectedTab === 'chat-notification'
                                                ? 'footer-menu-list active'
                                                : 'footer-menu-list'
                                        }
                                    >
                                        <div className="footer-menu-img">
                                            <img src={chat} className="svg" alt="Chat" />
                                            {parseInt(chatCount) > 0 && <p className="count">{chatCount}</p>}
                                        </div>
                                        <span>{i18n.t('chat')}</span>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="/"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ selectedTab: 'chat-notification' });
                                                this.redirectTo(
                                                    customFunctions.getUserId()
                                                        ? '/chat-notification'
                                                        : '/signup-with-social',
                                                );
                                            }}
                                        ></a>
                                    </li>
                                    <li
                                        className={
                                            selectedTab === 'ad-post' ? 'footer-menu-list active' : 'footer-menu-list'
                                        }
                                    >
                                        <div className="footer-menu-img">
                                            <img src={plus} className="svg" alt="Submit" />
                                        </div>
                                        <span>{i18n.t('submit')}</span>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="/"
                                            onClick={e => {
                                                this.adPostRedirect(e);
                                            }}
                                        ></a>
                                    </li>
                                    <li
                                        className={
                                            selectedTab === 'following' ? 'footer-menu-list active' : 'footer-menu-list'
                                        }
                                    >
                                        <div className="footer-menu-img">
                                            <img src={following} className="svg" alt="Following" />
                                        </div>
                                        <span>{i18n.t('following')}</span>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="/"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ selectedTab: 'following' });
                                                this.redirectTo(
                                                    customFunctions.getUserId() ? '/following' : '/signup-with-social',
                                                );
                                            }}
                                        ></a>
                                    </li>
                                    <li
                                        className={
                                            selectedTab === 'saved' ? 'footer-menu-list active' : 'footer-menu-list'
                                        }
                                    >
                                        <div className="footer-menu-img">
                                            <img src={saved} className="svg" alt="Saved" />
                                        </div>
                                        <span>{i18n.t('saved')}</span>
                                        {/* eslint-disable-next-line */}
                                        <a
                                            href="/"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({ selectedTab: 'saved' });
                                                this.redirectTo(
                                                    customFunctions.getUserId() ? '/saved' : '/signup-with-social',
                                                );
                                            }}
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    chat: state.chatNoti.chat,
    notification: state.chatNoti.notification,
});

const mapDispatchToProps = dispatch => ({
    isNewChat: chat => {
        dispatch(isNewChat(chat));
    },
    isNewNotification: notification => {
        dispatch(isNewNotification(notification));
    },
    setChatCountData: chat => {
        dispatch(setChatCount(chat));
    },
    clearAdsPostRedux: () => {
        dispatch(clearAdPostRedux());
    },
    clearLocationsRedux: () => {
        dispatch(clearLocationRedux());
    },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Footer);
