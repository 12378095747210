const init = {
    category: {},
    subCategory: {},
    attributes: [],
    selectedAttribute: {},
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'CATEGORY':
            return {
                ...state,
                category: action.category,
            };
        case 'SUBCATEGORY':
            return {
                ...state,
                subCategory: action.category,
            };
        case 'ATTRIBUTES':
            return {
                ...state,
                attributes: action.attributes,
            };
        case 'SELECTEDATTR':
            return {
                ...state,
                selectedAttribute: action.selectedAttr,
            };
        default:
            return state;
    }
};

export default reducer;
