import React, { PureComponent, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import customFunctions from './../util/customFunctions';

export default function asyncComponent(importComponent) {
    class AsyncComponent extends PureComponent {
        constructor(props) {
            super(props);

            this.state = {
                component: null,
            };
        }

        componentWillMount = async () => {
            var baseurl = window.location.origin;
            if (document.readyState === 'complete' || document.readyState === 'loading') {
                await customFunctions.loadScript(baseurl + '/assets/js/jquery-3.3.1.min.js');
                await customFunctions.loadScript(baseurl + '/assets/js/popper.min.js');
                await customFunctions.loadScript(baseurl + '/assets/js/bootstrap.min.js');
                await customFunctions.loadScript(baseurl + '/assets/js/scroll.js');
                await customFunctions.loadScript(baseurl + '/assets/js/custom.js');
                await customFunctions.addPaddingToTop();
            }
            await customFunctions.loadSvgImages();
        };

        componentDidMount = async () => {
            await customFunctions.addPaddingToTop();
            await customFunctions.loadSvgImages();
            const { default: component } = await importComponent();

            this.setState({
                component,
            });
        };

        render() {
            const C = this.state.component;
            return C ? (
                <Suspense
                    fallback={
                        <div className="loader-overlay full-loader">
                            <div className="loader">Loading...</div>
                        </div>
                    }
                >
                    <C {...this.props} />
                </Suspense>
            ) : null;
        }
    }

    return withRouter(AsyncComponent);
}
