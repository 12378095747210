import $ from 'jquery';
import { PureComponent } from 'react';
import cookie from 'react-cookies';
import { animateScroll } from 'react-scroll';
window.$ = $;
document.$ = $;

class CustomFunctions extends PureComponent {
    getDateTime(dateTime) {
        //eslint-disable-line
        const today = new Date(dateTime);
        const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        const time = (today.getHours() % 12 || 12) + ':' + today.getMinutes() + ':' + today.getSeconds();
        const ampm = today.getHours() >= 12 ? 'PM' : 'AM';
        return date + ' ' + time + ' ' + ampm;
    }

    getDate(dateTime) {
        //eslint-disable-line
        const today = new Date(dateTime);
        const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        return date;
    }

    getDisplayDate(dateTime, append = false) {
        const inputDate = new Date(dateTime);
        const year = inputDate.getFullYear();
        const month = inputDate.getMonth() + 1;
        const day = inputDate.getDate();
        const date = day + '/' + month + '/' + year;
        const time =
            (inputDate.getHours() % 12 || 12) +
            ':' +
            (inputDate.getMinutes() < 10 ? '0' + inputDate.getMinutes() : inputDate.getMinutes());
        const ampm = inputDate.getHours() >= 12 ? 'PM' : 'AM';

        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        var compDate = new Date(year, month - 1, day); // month - 1 because January == 0
        var diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
        if (compDate.getTime() === today.getTime()) {
            return '' + (append ? 'Today at ' + time + ' ' + ampm : time + ' ' + ampm); //"Today";
        } else if (diff <= 24 * 60 * 60 * 1000) {
            return 'Yesterday ' + (append ? 'at ' + time + ' ' + ampm : time + ' ' + ampm); //yesterday
        } else {
            return date + ' ' + time + ' ' + ampm; // or format it what ever way you want
        }
    }

    jsonParse = jsonString => {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            return jsonString;
        }
    };

    validateEmail = email => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
        return email.match(mailformat);
    };

    validatePhone = phone => {
        // var phoneformat = /^\d{10}$/; // eslint-disable-line
        // var phoneformat = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/;
        var phoneformat = /^(\+[0-9]{1,3})?([0-9]{10})$/;
        return phone.match(phoneformat);
    };

    loadScript = async src => {
        return new Promise(function (resolve, reject) {
            const ele = document.getElementById(src);
            if (ele) {
                ele.remove();
            }
            var script = document.createElement('script');
            script.id = src;
            script.src = src;
            script.type = 'text/javascript';
            script.async = false;
            script.addEventListener('load', function () {
                resolve();
            });
            script.addEventListener('error', function (e) {
                reject(e);
            });
            document.body.appendChild(script);
        });
        // script.type = "text/babel";
        // script.type = "text/jsx";
        //  document.getElementsByClassName("wrapper")[0].appendChild(script);
    };

    getSelectedLanguage = () => {
        var defaultLang = 'en';
        const lang = localStorage.getItem('language');
        if (lang) {
            defaultLang = lang;
        }
        return defaultLang;
    };

    previousRoute = () => {
        this.props.history.goBack();
    };

    redirectTo = path => {
        if (path) {
            this.props.history.push(path);
        }
    };

    UcFirst = string => {
        if (!string) {
            return '';
        }
        if (typeof string !== 'string') return '';
        const strArr = string.split(' ');
        const strLen = strArr.length;
        let UCStr = '';
        if (strLen > 1) {
            for (let i = 0; i < strLen; i++) {
                if (i === 0) {
                    UCStr = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
                } else if (i > 0) {
                    UCStr = UCStr + ' ' + strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
                }
            }
            return UCStr;
        } else if (strLen === 1) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return UCStr;
    };

    formatMoney = (number, currency) => {
        var code = '';
        switch (currency) {
            case 'USD':
                currency = 'USD';
                code = 'en-US';
                break;
            case 'ARD':
                currency = 'ARD';
                code = 'en-AR';
                break;
            case 'LBP':
                currency = 'LBP';
                code = 'en-AR';
                break;
            default:
                currency = 'USD';
                code = 'en-US';
                break;
        }

        return number.toLocaleString(code, { currencyDisplay: 'code', style: 'currency', currency });
    };

    formatOnlyAmount = (number, currency) => {
        var code = '';
        switch (currency) {
            case 'USD':
                code = 'en-US';
                break;
            case 'ARD':
                code = 'ar-SA';
                break;
            case 'LBP':
                code = 'en-US'; //'ar-AR';
                break;
            default:
                code = 'en-US';
                break;
        }
        return number.toLocaleString(code, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    capitalize = s => {
        if (!s) return '';
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    setPreviousPath = (path = '/') => {
        localStorage.setItem('previous_path', path);
    };

    getPreviousPath = () => {
        return localStorage.getItem('previous_path');
    };

    setUserId = (userId = '') => {
        localStorage.setItem('userId', userId);
    };

    getUserId = () => {
        return localStorage.getItem('userId');
    };

    setRoleId = (roleId = '') => {
        localStorage.setItem('roleId', roleId);
    };

    getRoleId = () => {
        return localStorage.getItem('roleId');
    };

    doIfUserLoggedIn = props => {
        if (this.getUserId()) {
            props.history.push('/home');
        }
    };

    doIfUserNotLoggedIn = props => {
        if (!this.getUserId()) {
            props.history.goBack();
        }
    };

    setUserData = user_data => {
        localStorage.setItem('user_data', JSON.stringify(user_data));
    };

    getUserData = () => {
        return JSON.parse(localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')) : '';
    };

    setLocFilter = locFilter => {
        localStorage.setItem('locFilter', JSON.stringify(locFilter));
    };

    getLocFilter = () => {
        return JSON.parse(localStorage.getItem('locFilter'));
    };

    getCATEGORY = () => {
        return JSON.parse(localStorage.getItem('CATEGORY')) ? JSON.parse(localStorage.getItem('CATEGORY')) : {};
    };

    setCATEGORY = (CATEGORY = {}) => {
        localStorage.setItem('CATEGORY', JSON.stringify(CATEGORY));
    };

    getSUBCATEGORY = () => {
        return JSON.parse(localStorage.getItem('SUBCATEGORY')) ? JSON.parse(localStorage.getItem('SUBCATEGORY')) : {};
    };

    setSUBCATEGORY = (SUBCATEGORY = {}) => {
        localStorage.setItem('SUBCATEGORY', JSON.stringify(SUBCATEGORY));
    };

    getATTRIBUTES = () => {
        return JSON.parse(localStorage.getItem('ATTRIBUTES')) ? JSON.parse(localStorage.getItem('ATTRIBUTES')) : [];
    };

    setATTRIBUTES = (ATTRIBUTES = []) => {
        localStorage.setItem('ATTRIBUTES', JSON.stringify(ATTRIBUTES));
    };

    getATTRIBUTEANS = () => {
        return JSON.parse(localStorage.getItem('ATTRIBUTEANS')) ? JSON.parse(localStorage.getItem('ATTRIBUTEANS')) : {};
    };

    setATTRIBUTEANS = (ATTRIBUTEANS = {}) => {
        localStorage.setItem('ATTRIBUTEANS', JSON.stringify(ATTRIBUTEANS));
    };

    getATTRINDEX = () => {
        return localStorage.getItem('ATTRINDEX')
            ? localStorage.getItem('ATTRINDEX')
            : localStorage.getItem('ATTRINDEX') === 0
            ? localStorage.getItem('ATTRINDEX')
            : -1;
    };

    setATTRINDEX = (ATTRINDEX = -1) => {
        localStorage.setItem('ATTRINDEX', ATTRINDEX);
    };

    getISDIRECT = () => {
        return localStorage.getItem('ISDIRECT') ? localStorage.getItem('ISDIRECT') : false;
    };

    setISDIRECT = (ISDIRECT = false) => {
        localStorage.setItem('ISDIRECT', ISDIRECT);
    };

    getTITLE = () => {
        return localStorage.getItem('TITLE') ? localStorage.getItem('TITLE') : '';
    };

    setTITLE = (TITLE = '') => {
        localStorage.setItem('TITLE', TITLE);
    };

    getDESCRIPTION = () => {
        return localStorage.getItem('DESCRIPTION') ? localStorage.getItem('DESCRIPTION') : '';
    };

    setDESCRIPTION = (DESCRIPTION = '') => {
        localStorage.setItem('DESCRIPTION', DESCRIPTION);
    };

    getPRICE = () => {
        return localStorage.getItem('PRICE') ? localStorage.getItem('PRICE') : '';
    };

    setPRICE = (PRICE = '') => {
        localStorage.setItem('PRICE', PRICE);
    };

    getMOBILE = () => {
        return localStorage.getItem('MOBILE') ? localStorage.getItem('MOBILE') : '';
    };

    setMOBILE = (MOBILE = '') => {
        localStorage.setItem('MOBILE', MOBILE);
    };

    getWHATSAPPMOBILE = () => {
        return localStorage.getItem('WHATSAPPMOBILE') ? localStorage.getItem('WHATSAPPMOBILE') : '';
    };

    setWHATSAPPMOBILE = (WHATSAPPMOBILE = '') => {
        localStorage.setItem('WHATSAPPMOBILE', WHATSAPPMOBILE);
    };

    getEMAIL = () => {
        return localStorage.getItem('EMAIL') ? localStorage.getItem('EMAIL') : '';
    };

    setEMAIL = (EMAIL = '') => {
        localStorage.setItem('EMAIL', EMAIL);
    };

    getNAME = () => {
        return localStorage.getItem('NAME') ? localStorage.getItem('NAME') : '';
    };

    setNAME = (NAME = '') => {
        localStorage.setItem('NAME', NAME);
    };

    getCONTACTBY = () => {
        return localStorage.getItem('CONTACTBY') ? localStorage.getItem('CONTACTBY') : 'phone';
    };

    setCONTACTBY = (CONTACTBY = 'phone') => {
        localStorage.setItem('CONTACTBY', CONTACTBY);
    };

    getEXTRAS = () => {
        return JSON.parse(localStorage.getItem('EXTRAS')) ? JSON.parse(localStorage.getItem('EXTRAS')) : [];
    };

    setEXTRAS = (EXTRAS = []) => {
        localStorage.setItem('EXTRAS', JSON.stringify(EXTRAS));
    };

    getADSIMAGES = () => {
        return JSON.parse(localStorage.getItem('ADSIMAGES')) ? JSON.parse(localStorage.getItem('ADSIMAGES')) : [];
    };

    setADSIMAGES = (ADSIMAGES = []) => {
        localStorage.setItem('ADSIMAGES', JSON.stringify(ADSIMAGES));
    };

    getADSID = () => {
        return localStorage.getItem('ADSID') ? localStorage.getItem('ADSID') : null;
    };

    setADSID = (ADSID = '') => {
        localStorage.setItem('ADSID', ADSID);
    };

    getADIDBYDATA = () => {
        return JSON.parse(localStorage.getItem('ADIDBYDATA')) ? JSON.parse(localStorage.getItem('ADIDBYDATA')) : {};
    };

    setADIDBYDATA = (ADIDBYDATA = {}) => {
        localStorage.setItem('ADIDBYDATA', JSON.stringify(ADIDBYDATA));
    };

    getPROVINCE = () => {
        return JSON.parse(localStorage.getItem('PROVINCE')) ? JSON.parse(localStorage.getItem('PROVINCE')) : {};
    };

    setPROVINCE = (PROVINCE = {}) => {
        localStorage.setItem('PROVINCE', JSON.stringify(PROVINCE));
    };

    getDISTRICT = () => {
        return JSON.parse(localStorage.getItem('DISTRICT')) ? JSON.parse(localStorage.getItem('DISTRICT')) : {};
    };

    setDISTRICT = (DISTRICT = {}) => {
        localStorage.setItem('DISTRICT', JSON.stringify(DISTRICT));
    };

    getCITY = () => {
        return JSON.parse(localStorage.getItem('CITY')) ? JSON.parse(localStorage.getItem('CITY')) : {};
    };

    setCITY = (CITY = {}) => {
        localStorage.setItem('CITY', JSON.stringify(CITY));
    };

    clearAdPostlocalStorage = async () => {
        await this.clearCategorylocalStorage();
        await this.clearLocationlocalStorage();
        await localStorage.removeItem('TITLE');
        await localStorage.removeItem('DESCRIPTION');
        await localStorage.removeItem('PRICE');
        await localStorage.removeItem('MOBILE');
        await localStorage.removeItem('WHATSAPPMOBILE');
        await localStorage.removeItem('EMAIL');
        await localStorage.removeItem('NAME');
        await localStorage.removeItem('CONTACTBY');
        await localStorage.removeItem('CONTACTVIA');
        await localStorage.removeItem('ADSIMAGES');
        await localStorage.removeItem('ADSID');
        await localStorage.removeItem('ADIDBYDATA');
        await localStorage.removeItem('MOBILECODE');
        await localStorage.removeItem('WHATSAPPMOBILECODE');
        await localStorage.removeItem('ADCURRENCY');
    };

    clearCategorylocalStorage = async () => {
        await localStorage.removeItem('CATEGORY');
        await localStorage.removeItem('SUBCATEGORY');
        await localStorage.removeItem('ATTRIBUTES');
        await localStorage.removeItem('ATTRIBUTEANS');
        await localStorage.removeItem('ATTRINDEX');
        await localStorage.removeItem('ISDIRECT');
        await localStorage.removeItem('EXTRAS');

        await localStorage.removeItem('category');
        await localStorage.removeItem('subCategory');
        await localStorage.removeItem('attributes');
        await localStorage.removeItem('selectedAns');
        await localStorage.removeItem('attrPropAns');
        await localStorage.removeItem('adsFilter');
        await localStorage.removeItem('filterGroup');
    };

    clearLocationlocalStorage = async () => {
        await localStorage.removeItem('PROVINCE');
        await localStorage.removeItem('DISTRICT');
        await localStorage.removeItem('CITY');
    };

    getCurrentLanguage = () => {
        const lang = localStorage.getItem('language') || 'en';
        return lang;
    };

    phoneMasking(value) {
        let newVal = value.replace(/\D/g, '').replace(/^0+/, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length > 15) {
            newVal = newVal.substring(0, 15);
        }
        return newVal;
    }

    setlocalStorage = (key = '', value = '') => {
        if (key) localStorage.setItem(key, value);
    };

    getlocalStorage = (key = '') => {
        if (!key) return key;

        return localStorage.getItem(key);
    };

    removelocalStorage(key = '') {
        if (!key) return key;

        localStorage.removeItem(key);
    }

    addPaddingToTop = async () => {
        let headerHeight = await $('header').height();
        document.body.style.paddingTop = headerHeight ? `${headerHeight}px` : `0px`;
        this.loadSvgImages();
    };

    doIfUserIsUnauthorized = async () => {
        await localStorage.clear();
        window.location.pathname = '/home';
    };

    getEndPointIfLocation = () => {
        const locFilter = this.getLocFilter();
        let endPoint = '';
        if (locFilter) {
            if (locFilter.province && locFilter.province.length) {
                let stateIds = [];
                for (let i = 0, n = locFilter.province.length; i < n; i++) {
                    stateIds.push(locFilter.province[i].id);
                }
                endPoint = `${endPoint}&state_ids=[${stateIds.join()}]`;
            }
            if (locFilter.district && locFilter.district.length) {
                let cityIds = [];
                for (let i = 0, n = locFilter.district.length; i < n; i++) {
                    cityIds.push(locFilter.district[i].id);
                }
                endPoint = `${endPoint}&city_ids=[${cityIds.join()}]`;
            }
            if (locFilter.city && locFilter.city.length) {
                let localityIds = [];
                for (let i = 0, n = locFilter.city.length; i < n; i++) {
                    localityIds.push(locFilter.city[i].id);
                }
                endPoint = `${endPoint}&locality_ids=[${localityIds.join()}]`;
            }
        }
        return endPoint;
    };

    titleSubString = (str, len = 100) => {
        if (!str) return '';

        return str.substring(0, len) + (str.length > len ? '...' : '');
    };

    setOnEnterPressEvent = refs => {
        if (refs) {
            let refArr = Object.keys(refs);
            for (let ref_name in refs) {
                refs[ref_name].onkeypress = e => this.handleKeyPress(e, ref_name, refs, refArr);
            }
        }
    };

    handleKeyPress = (e, ref_name, refs, refArr) => {
        let focusEleIdx = refArr.indexOf(ref_name);
        if (focusEleIdx !== -1 && focusEleIdx !== refArr.length - 1) {
            let newFocusIdx = focusEleIdx + 1;
            if (e.keyCode === 13) {
                e.preventDefault();
                let next = refs[refArr[newFocusIdx]];
                if (next && refArr[newFocusIdx] !== 'submit') {
                    refs[refArr[newFocusIdx]].focus();
                }
                if (next && refArr[newFocusIdx] === 'submit') {
                    refs[refArr[newFocusIdx]].click();
                }
            }
        }
    };

    getCurrencyCode = (type = 'lbp') => {
        if (type === 'lbp' || !type) {
            return 'LBP';
        } else if (type === 'usd') {
            return 'USD';
        }
    };

    getCurrencySymbol = (type = 'lbp', value = 0) => {
        if (type === 'lbp' || !type) {
            if (this.getCurrentLanguage() === 'en') {
                return 'LBP' + value;
            }
            return value + 'ل.ل.';
        } else if (type === 'usd') {
            return '$' + value;
        }
    };

    getCONTACTVIA = () => {
        return JSON.parse(localStorage.getItem('CONTACTVIA'))
            ? JSON.parse(localStorage.getItem('CONTACTVIA'))
            : { phone: true, email: false, chat: true, call: true };
    };

    setCONTACTVIA = (CONTACTBY = {}) => {
        localStorage.setItem('CONTACTVIA', JSON.stringify(CONTACTBY));
    };

    validateCountryCode = phone => {
        var codeformat = /^(\+[0-9]{1,3})$/;
        return phone.match(codeformat);
    };

    countryCodeMasking(value) {
        let newValue = value.split('+');
        if (newValue[1]) {
            let newVal = newValue[1].replace(/\D/g, '').replace(/^0+/, '');
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length > 3) {
                newVal = newVal.substring(0, 3);
            }
            return '+' + newVal;
        } else {
            let newVal = value.replace(/^0+/, '').replace(/\D/g, '+');
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length) {
                if (!newVal.startsWith('+')) {
                    newVal = '+' + newVal;
                }
            } else if (newVal.length > 3) {
                newVal = newVal.substring(0, 3);
            }
            return newVal;
        }
    }

    getMOBILECODE = () => {
        return localStorage.getItem('MOBILECODE') ? localStorage.getItem('MOBILECODE') : '';
    };

    setMOBILECODE = (MOBILECODE = '') => {
        localStorage.setItem('MOBILECODE', MOBILECODE);
    };

    getWHATSAPPMOBILECODE = () => {
        return localStorage.getItem('WHATSAPPMOBILECODE') ? localStorage.getItem('WHATSAPPMOBILECODE') : '';
    };

    setWHATSAPPMOBILECODE = (WHATSAPPMOBILECODE = '') => {
        localStorage.setItem('WHATSAPPMOBILECODE', WHATSAPPMOBILECODE);
    };

    phoneWithOrWithoutCodeMasking(value) {
        if (!value.startsWith('+')) {
            return this.phoneMaskingWithCode(value);
        } else if (value.startsWith('+')) {
            return this.phoneWithCodeMasking(value);
        }
    }

    phoneMaskingWithCode(value) {
        let newVal = value.replace(/[^0-9-+]/g, '').replace(/^0+/, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length > 10) {
            newVal = '+' + newVal;
        } else if (newVal.length > 15) {
            newVal = newVal.substring(0, 15);
        }
        return newVal;
    }

    phoneWithCodeMasking(value) {
        let newValue = value.split('+');
        if (newValue[1]) {
            let newVal = newValue[1].replace(/[^0-9-+]/g, '').replace(/^0+/, '');
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length > 15) {
                newVal = newVal.substring(0, 15);
            }
            if (newVal.length <= 10) {
                //return newVal;
            }
            return '+' + newVal;
        }
    }

    setLoginAsGuest = LoginAsGuest => {
        localStorage.setItem('LoginAsGuest', LoginAsGuest);
    };

    getLoginAsGuest = () => {
        return localStorage.getItem('LoginAsGuest') ? localStorage.getItem('LoginAsGuest') : false;
    };

    getADCURRENCY = () => {
        return localStorage.getItem('ADCURRENCY') ? localStorage.getItem('ADCURRENCY') : 'lbp';
    };

    setADCURRENCY = (ADCURRENCY = 'lbp') => {
        localStorage.setItem('ADCURRENCY', ADCURRENCY);
    };

    loadSvgImages = async () => {
        $('img.svg').each(function () {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(
                imgURL,
                function (data) {
                    var $svg = $(data).find('svg');
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }
                    $svg = $svg.removeAttr('xmlns:a');
                    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                    }
                    $img.replaceWith($svg);
                },
                'xml',
            );
        });
    };

    intlNumberFormate = number => {
        var formatedNumber = '';
        if (number) {
            formatedNumber = number.replace(/^0+/g, '').replace(/[^0-9+]/g, '');
        }
        return formatedNumber;
    };

    setProfileLanguage = lang => {
        var tspanX = Number(5.336);
        tspanX = lang === 'en' ? -tspanX : tspanX;
        $('.lang tspan').attr('x', tspanX);
    };

    getScrollCond() {
        if (
            (window.innerWidth <= 760 &&
                window.innerHeight + document.documentElement.scrollTop >=
                    document.documentElement.offsetHeight - 25) ||
            window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
        ) {
            return true;
        }
        return false;
    }

    scrollTo(value) {
        animateScroll.scrollTo(value);
    }

    scrollToBottom() {
        animateScroll.scrollToBottom();
    }

    scrollToTop(options = null) {
        animateScroll.scrollToTop(options);
    }

    setLoginSession = async (data = null) => {
        if (data) {
            var expires = 365 * 24 * 60 * 60;
            cookie.save('usertoken', data.token, { path: '/', maxAge: expires });
            await this.setUserId(data.id);
            await this.setRoleId(data.role);
        }
    };

    setLoginType = (LoginType = '') => {
        localStorage.setItem('LoginType', LoginType);
    };

    getLoginType = () => {
        return localStorage.getItem('LoginType');
    };

    filterProperties = async (type = 'all', properties) => {
        if (type === 'all') {
            const filtered = await properties.sort(function (a, b) {
                return a.group_order_by - b.group_order_by || a.property_order_by - b.property_order_by;
            });
            return filtered;
        } else {
            const filtered = await properties.sort(function (a, b) {
                return a.property_order_by - b.property_order_by;
            });
            return filtered;
        }
    };

    getADSUPLOADEDIMAGES = () => {
        return localStorage.getItem('ADSUPLOADEDIMAGES')
            ? this.jsonParse(localStorage.getItem('ADSUPLOADEDIMAGES'))
            : [];
    };

    setADSUPLOADEDIMAGES = (ADSUPLOADEDIMAGES = []) => {
        localStorage.setItem('ADSUPLOADEDIMAGES', JSON.stringify(ADSUPLOADEDIMAGES));
    };

    generateUrl = (url, extraParam = '', urlParams = {}) => {
        const searchParams = new URLSearchParams(this.cleanObjects(urlParams)).toString();
        let apiEndpoint = url;
        if (extraParam) apiEndpoint = `${apiEndpoint}/${extraParam}`;
        const returnTaskApi = `${apiEndpoint}${searchParams ? '?' + searchParams : ''}`;
        return returnTaskApi;
    };

    cleanObjects = obj => {
        return Object.entries(obj).reduce((a, [k, v]) => (v || v === false || v === 0 ? ((a[k] = v), a) : a), {});
    };
}

export default new CustomFunctions();
