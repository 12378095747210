import axios from 'axios';
import cookie from 'react-cookies';
import customFunctions from '../../util/customFunctions';

export default {
    setupInterceptors: async () => {
        await axios.interceptors.request.use(
            async config => {
                // const configuration = config;
                // if (configuration.url.indexOf('upload') > -1) {
                //     configuration.headers['Content-Type'] = 'multipart/form-data'; // eslint-disable-line
                // } else {
                //     configuration.headers['Content-Type'] = 'application/json'; // eslint-disable-line
                // }
                config.headers['Content-Type'] = 'application/json'; // eslint-disable-line
                // config.headers['Access-Key'] = 'AE698wLwHGPLvtuzF46V4P2h4yh3ru2MmkBKpsEA7bzQSHjQ3F'; // eslint-disable-line

                const token = await cookie.load('usertoken');
                const language = (await localStorage.getItem('language')) || 'en';
                if (token && token.access_token) config.headers.Authorization = `Bearer ${token.access_token}`; // eslint-disable-line
                config.url =
                    config.url.indexOf('?') > -1
                        ? `${config.url}&language=${language}`
                        : `${config.url}?language=${language}`;
                return config;
            },
            error => {
                Promise.reject(error);
            },
        );

        await axios.interceptors.response.use(
            response => response,
            async error => {
                const data = await error.response;
                if (data && (data.status === 401 || data.statusText === 'Unauthorized')) {
                    await cookie.remove('usertoken');
                    await customFunctions.doIfUserIsUnauthorized();
                    return Promise.reject(error.response.data);
                }
                return Promise.reject(error.response);
            },
        );
    },
};
