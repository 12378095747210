import i18n from 'i18next';
import React, { PureComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Trans } from 'react-i18next';

class OfflineModel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { show } = this.props;
        return (
            <Modal show={show} centered size="sm" backdrop={true} keyboard={false}>
                <div className="" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title w-100">{i18n.t('youAreOffline')}</h5>
                        </div>
                        <Modal.Body>
                            <p className="mb-0">
                                <Trans i18nKey="youAreOfflineBody"></Trans>
                            </p>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default OfflineModel;
