import { combineReducers } from 'redux';
import adReducer from './reducers/ads.reducer';
import locationReducer from './reducers/location.reducer';
import userReducer from './reducers/user.reducer';
import adpostReducer from './reducers/adpost.reducer';
import promoteAds from './reducers/promoteAds.reducer';
import chatnoti from './reducers/chatnoti.reducer';
// Combine with other reducers we may add in the future
const reducers = combineReducers({
    ads: adReducer,
    location: locationReducer,
    user: userReducer,
    adPost: adpostReducer,
    adsPromote: promoteAds,
    chatNoti: chatnoti,
});

export default reducers;
