const init = {
    category: {},
    subCategory: {},
    attributes: [],
    attributeAns: {},
    attributeIndex: -1,
    isDirect: false,
    adExtras: [],
    adImages: [],
    adTitle: '',
    adDescription: '',
    adPrice: '',
    adMobile: '',
    adWhatsappMobile: '',
    adEmail: '',
    adName: '',
    adContactBy: 'phone',
    adId: null,
    adIdData: {},
    contacts_via: { phone: true, email: false, chat: true, call: true },
    adMobileCode: '',
    adWpMobileCode: '',
    adCurrency: 'lbp',
    adsUploadedImages: [],
};

const initCategory = {
    category: {},
    subCategory: {},
    attributes: [],
    attributeAns: {},
    attributeIndex: -1,
    isDirect: false,
    adExtras: [],
};

export const addPostCategory = category => ({
    type: 'CATEGORY',
    category,
});

export const addPostSubCategory = category => ({
    type: 'SUBCATEGORY',
    category,
});

export const addPostAttributes = attributes => ({
    type: 'ATTRIBUTES',
    attributes,
});

export const addPostAttributesAns = attributesAns => ({
    type: 'ATTRIBUTEANS',
    attributesAns,
});

export const addAttributeIndex = index => ({
    type: 'ATTRINDEX',
    index,
});

export const setIsDirect = isDirect => ({
    type: 'ISDIRECT',
    isDirect,
});

export const setAdTitle = adTitle => ({
    type: 'TITLE',
    adTitle,
});

export const setAdDescription = adDescription => ({
    type: 'DESCRIPTION',
    adDescription,
});

export const setAdPrice = adPrice => ({
    type: 'PRICE',
    adPrice,
});

export const setAdMobile = adMobile => ({
    type: 'MOBILE',
    adMobile,
});

export const setAdWhatsappMobile = adWhatsappMobile => ({
    type: 'WHATSAPPMOBILE',
    adWhatsappMobile,
});

export const setAdEmail = adEmail => ({
    type: 'EMAIL',
    adEmail,
});

export const setAdName = adName => ({
    type: 'NAME',
    adName,
});

export const setAdContactBy = adContactBy => ({
    type: 'CONTACTBY',
    adContactBy,
});

export const setContactsVia = contacts_via => ({
    type: 'CONTACTVIA',
    contacts_via,
});

export const setAdExtras = adExtras => ({
    type: 'EXTRAS',
    adExtras,
});

export const setAdImages = adImages => ({
    type: 'ADSIMAGES',
    adImages,
});

export const setAdId = adId => ({
    type: 'ADSID',
    adId,
});

export const setAdIdByData = adIdData => ({
    type: 'ADIDBYDATA',
    adIdData,
});

export const clearAdPostRedux = () => ({
    type: 'CLEARADPOST',
    init,
});

export const clearCategoryRedux = () => ({
    type: 'CLEARCATEGORY',
    initCategory,
});

export const setAdMobileCode = adMobileCode => ({
    type: 'MOBILECODE',
    adMobileCode,
});

export const setAdWhatsappMobileCode = adWpMobileCode => ({
    type: 'WHATSAPPMOBILECODE',
    adWpMobileCode,
});

export const setAdCurrency = adCurrency => ({
    type: 'ADCURRENCY',
    adCurrency,
});

export const setAdsUploadedImages = adsUploadedImages => ({
    type: 'ADSUPLOADEDIMAGES',
    adsUploadedImages,
});
