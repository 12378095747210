const init = {
    chat: false,
    notification: false,
    chatCount: {},
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'CHAT':
            return {
                ...state,
                chat: action.chat,
            };
        case 'NOTIFICATION':
            return {
                ...state,
                notification: action.notification,
            };
        case 'CHATCOUNT':
            return {
                ...state,
                chatCount: action.chatCount,
            };
        default:
            return state;
    }
};

export default reducer;
