const init = {
    province: {},
    district: {},
    city: {},
    setUserLocation: false,
    id: null,
    name: '',
    email: '',
    userImage: '',
    mobileNumber: '',
    mobilecode: '',
    whtsappcode: '',
    whatsAppNumber: '',
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'PROVINCE':
            return {
                ...state,
                province: action.province,
            };
        case 'DISTRICT':
            return {
                ...state,
                district: action.district,
            };
        case 'CITY':
            return {
                ...state,
                city: action.city,
            };
        case 'USERLOCATION':
            return {
                ...state,
                setUserLocation: action.userLocation,
            };
        case 'ID':
            return {
                ...state,
                id: action.id,
            };
        case 'NAME':
            return {
                ...state,
                name: action.name,
            };
        case 'EMAIL':
            return {
                ...state,
                email: action.email,
            };
        case 'IMAGE':
            return {
                ...state,
                userImage: action.userImage,
            };
        case 'MOBILENO':
            return {
                ...state,
                mobileNumber: action.mobileNumber,
            };
        case 'MOBILENOCODE':
            return {
                ...state,
                mobilecode: action.mobilecode,
            };
        case 'WHATSAPPNO':
            return {
                ...state,
                whatsAppNumber: action.whatsAppNumber,
            };
        case 'WHATSAPPNOCODE':
            return {
                ...state,
                whtsappcode: action.whtsappcode,
            };
        case 'CLEARUSERREDUX':
            return {
                ...action.init,
            };

        default:
            return state;
    }
};

export default reducer;
