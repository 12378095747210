import React, { PureComponent, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import './../assets/css/bootstrap.min.css';
import './../assets/css/style.css';
import customFunctions from './../util/customFunctions';

class NoFooterLayout extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => {
        // await customFunctions.loadScript("./../assets/js/jquery-3.3.1.min.js");
        // await customFunctions.loadScript("./../assets/js/popper.min.js");
        // await customFunctions.loadScript("./../assets/js/bootstrap.min.js");
        // await customFunctions.loadScript("./../assets/js/scroll.js");
        // await customFunctions.loadScript("./../assets/js/custom.js");
        customFunctions.addPaddingToTop();
    };

    render() {
        const { children } = this.props;

        return (
            <Suspense
                fallback={
                    <div className="loader-overlay full-loader">
                        <div className="loader">Loading...</div>
                    </div>
                }
            >
                <div className="wrapper">{children}</div>
            </Suspense>
        );
    }
}

export default withRouter(NoFooterLayout);
