const init = {
    cost: null,
    duration: null,
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'COST':
            return {
                ...state,
                cost: action.cost,
            };
        case 'DURATION':
            return {
                ...state,
                duration: action.duration,
            };
        default:
            return state;
    }
};

export default reducer;
