const init = {
    province: {},
    district: {},
    city: {},
};

export const addProvince = province => ({
    type: 'PROVINCE',
    province,
});

export const addDistrict = district => ({
    type: 'DISTRICT',
    district,
});

export const addCity = city => ({
    type: 'CITY',
    city,
});

export const clearLocationRedux = () => ({
    type: 'CLEARLOCATION',
    init,
});
