// import './App.css';
import i18n from 'i18next';
import React, { PureComponent } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import NotificationPusher from './components/NotificationPusher/notificationPusher.component';
import './i18n';
import Routes from './routes';
import Api from './util/api';
import authApi from './util/authApi';
import customFunctions from './util/customFunctions';

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount = async () => {
        const { data } = await authApi.getDataFromServer(Api.basicSettingsUrl);
        if (!data) return;
        const settings = data.data;
        var logoSetting = settings.filter(setting => {
            return setting.key === 'logo';
        });
        if (!logoSetting || logoSetting.length === 0) return;
        if (logoSetting[0].image && logoSetting[0].image) {
            var small = `${Api.IMAGE_URL}${logoSetting[0].image.small}`;
            var medium = `${Api.IMAGE_URL}${logoSetting[0].image.medium}`;
            var large = `${Api.IMAGE_URL}${logoSetting[0].image.large}`;
            const logoUrl = {
                small,
                medium,
                large,
            };
            await localStorage.setItem('logoUrl', JSON.stringify(logoUrl));
        } else {
            await localStorage.setItem('logoUrl', '');
        }
    };

    componentDidMount = async () => {
        setTimeout(async () => {
            var lang = (await localStorage.getItem('language')) || 'en';
            i18n.changeLanguage(lang);
            localStorage.setItem('language', lang);
            if (lang === 'ar') document.body.dir = 'rtl';
            this.forceUpdate();
        }, 200);
        customFunctions.addPaddingToTop();
    };

    render() {
        const childProps = {};
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Libanx</title>
                        <meta name="description" content="All type of Ads are here"></meta>
                        <link rel="canonical" href={this.props.location.pathname} />
                    </Helmet>
                </HelmetProvider>
                <Routes childProps={childProps} />
                <NotificationPusher />
            </>
        );
    }
}

export default withRouter(App);
