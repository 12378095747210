import customFunctions from './../util/customFunctions';

const init = {
    category: customFunctions.getCATEGORY(),
    subCategory: customFunctions.getSUBCATEGORY(),
    attributes: customFunctions.getATTRIBUTES(),
    attributeAns: customFunctions.getATTRIBUTEANS(),
    attributeIndex: customFunctions.getATTRINDEX(),
    isDirect: customFunctions.getISDIRECT(),
    adExtras: customFunctions.getEXTRAS(),
    adImages: customFunctions.getADSIMAGES(),
    adTitle: customFunctions.getTITLE(),
    adDescription: customFunctions.getDESCRIPTION(),
    adPrice: customFunctions.getPRICE(),
    adMobile: customFunctions.getMOBILE(),
    adWhatsappMobile: customFunctions.getWHATSAPPMOBILE(),
    adEmail: customFunctions.getEMAIL(),
    adName: customFunctions.getNAME(),
    adContactBy: customFunctions.getCONTACTBY(),
    contacts_via: customFunctions.getCONTACTVIA(),
    adId: customFunctions.getADSID(),
    adIdData: customFunctions.getADIDBYDATA(),
    adMobileCode: customFunctions.getMOBILECODE(),
    adWpMobileCode: customFunctions.getWHATSAPPMOBILECODE(),
    adCurrency: customFunctions.getADCURRENCY(),
    adsUploadedImages: customFunctions.getADSUPLOADEDIMAGES(),
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'CATEGORY':
            customFunctions.setCATEGORY(action.category);
            return {
                ...state,
                category: action.category,
            };
        case 'SUBCATEGORY':
            customFunctions.setSUBCATEGORY(action.category);
            return {
                ...state,
                subCategory: action.category,
            };
        case 'ATTRIBUTES': {
            customFunctions.setATTRIBUTES(action.attributes);
            return {
                ...state,
                attributes: action.attributes,
            };
        }
        case 'ATTRIBUTEANS': {
            customFunctions.setATTRIBUTEANS(action.attributesAns);
            return {
                ...state,
                attributeAns: action.attributesAns,
            };
        }
        case 'ATTRINDEX': {
            customFunctions.setATTRINDEX(action.index);
            return {
                ...state,
                attributeIndex: action.index,
            };
        }
        case 'ISDIRECT': {
            customFunctions.setISDIRECT(action.isDirect);
            return {
                ...state,
                isDirect: action.isDirect,
            };
        }
        case 'TITLE':
            customFunctions.setTITLE(action.adTitle);
            return {
                ...state,
                adTitle: action.adTitle,
            };
        case 'DESCRIPTION':
            customFunctions.setDESCRIPTION(action.adDescription);
            return {
                ...state,
                adDescription: action.adDescription,
            };

        case 'PRICE': {
            customFunctions.setPRICE(action.adPrice);
            return {
                ...state,
                adPrice: action.adPrice,
            };
        }
        case 'MOBILE': {
            customFunctions.setMOBILE(action.adMobile);
            return {
                ...state,
                adMobile: action.adMobile,
            };
        }
        case 'WHATSAPPMOBILE': {
            customFunctions.setWHATSAPPMOBILE(action.adWhatsappMobile);
            return {
                ...state,
                adWhatsappMobile: action.adWhatsappMobile,
            };
        }
        case 'EMAIL': {
            customFunctions.setEMAIL(action.adEmail);
            return {
                ...state,
                adEmail: action.adEmail,
            };
        }
        case 'NAME': {
            customFunctions.setNAME(action.adName);
            return {
                ...state,
                adName: action.adName,
            };
        }
        case 'CONTACTBY': {
            customFunctions.setCONTACTBY(action.adContactBy);
            return {
                ...state,
                adContactBy: action.adContactBy,
            };
        }
        case 'CONTACTVIA': {
            customFunctions.setCONTACTVIA(action.contacts_via);
            return {
                ...state,
                contacts_via: action.contacts_via,
            };
        }
        case 'EXTRAS': {
            customFunctions.setEXTRAS(action.adExtras);
            return {
                ...state,
                adExtras: action.adExtras,
            };
        }
        case 'ADSIMAGES': {
            customFunctions.setADSIMAGES(action.adImages);
            return {
                ...state,
                adImages: action.adImages,
            };
        }
        case 'ADSID': {
            customFunctions.setADSID(action.adId);
            return {
                ...state,
                adId: action.adId,
            };
        }
        case 'ADIDBYDATA': {
            customFunctions.setADIDBYDATA(action.adIdData);
            return {
                ...state,
                adIdData: action.adIdData,
            };
        }
        case 'CLEARADPOST':
            customFunctions.clearAdPostlocalStorage();
            return {
                ...action.init,
            };
        case 'CLEARCATEGORY':
            customFunctions.clearCategorylocalStorage();
            return {
                ...init,
                ...action.initCategory,
            };
        case 'MOBILECODE': {
            customFunctions.setMOBILECODE(action.adMobileCode);
            return {
                ...state,
                adMobileCode: action.adMobileCode,
            };
        }
        case 'WHATSAPPMOBILECODE': {
            customFunctions.setWHATSAPPMOBILECODE(action.adWpMobileCode);
            return {
                ...state,
                adWpMobileCode: action.adWpMobileCode,
            };
        }
        case 'ADCURRENCY': {
            customFunctions.setADCURRENCY(action.adCurrency);
            return {
                ...state,
                adCurrency: action.adCurrency,
            };
        }
        case 'ADSUPLOADEDIMAGES': {
            customFunctions.setADSUPLOADEDIMAGES(action.adsUploadedImages);
            return {
                ...state,
                adsUploadedImages: action.adsUploadedImages,
            };
        }
        default:
            return state;
    }
};

export default reducer;
