import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import './index.css';
import App from './App';
import userReducers from './reducers';
import * as serviceWorker from './serviceWorker';
import Interceptor from './services/Interceptor';
//const BrowserHistory = require('react-router/lib/BrowserHistory').default;
//import * as H from 'history';
import { createBrowserHistory } from 'history';
import Routes from './routes';

const history = createBrowserHistory();
const store = createStore(userReducers);

Interceptor.setupInterceptors();
ReactDOM.render(
    <Provider store={store}>
        <Suspense
            fallback={
                <div className="loader-overlay full-loader">
                    <div className="loader">Loading...</div>
                </div>
            }
        >
            <Router history={history} routes={Routes}>
                <App />
            </Router>
        </Suspense>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
