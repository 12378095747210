// import React from 'react';

export const facebookConfig = {
    appId: '1362965100530397',
};
export const googleConfig = {
    clientId: '1011518052970-ikrlrh6bdja8nl9u5ni5862linubrn9g.apps.googleusercontent.com',
};
export const stripe = {
    publish_key: 'pk_test_ZanqwjnfaDczqULkJHAN3O8x',
    secretKey: 'sk_test_KOy9aebSvQX5bX4Yd6won3me',
};
export const paypal = {
    client: {
        sandbox: 'AaTVCpPO30fKHnrbNU9yhcvJHOyBthq4OVjIt4KS1iooQ2lU_EkaYIrQrAx1rrqO-IjV3d31fM-aX0nN',
        production: 'AaTVCpPO30fKHnrbNU9yhcvJHOyBthq4OVjIt4KS1iooQ2lU_EkaYIrQrAx1rrqO-IjV3d31fM-aX0nN',
    },
    env: 'sandbox',
};
export const pusher = {
    APP_KEY: '1d58ee7b75c85accc630',
    APP_CLUSTER: 'ap2',
};

export const s3Config = {
    bucketName: 'libanx-dev',
    dirName: 'Chat' /* optional */,
    region: 'me-south-1',
    accessKeyId: 'AKIAQ7SAF2PS7RGBDJSQ',
    secretAccessKey: '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',
};
