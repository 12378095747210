import { PureComponent } from 'react';

export default class Api extends PureComponent {
    static isStaging = false;

    static baseUrl = Api.getBaseUrl();
    static IMAGE_URL = Api.getImageBaseUrl();

    static loginUrl = `${Api.baseUrl}login/`;
    static logoutUrl = `${Api.baseUrl}logout/`;
    static resetPassUrl = `${Api.baseUrl}reset-password/`;
    static verifyOtpUrl = `${Api.baseUrl}verify-otp/`;
    static verifyProfileOtpUrl = `${Api.baseUrl}profile-verify-otp/`;
    static resendOtpUrl = `${Api.baseUrl}resend-otp/`;
    static forgetPasswordUrl = `${Api.baseUrl}forget-password/`;
    static changePasswordUrl = `${Api.baseUrl}change-password/`;
    static usersUrl = `${Api.baseUrl}users/`;
    static usersStatusUrl = `${Api.baseUrl}users-status-change/`;
    static rolesUrl = `${Api.baseUrl}roles/`;
    static facebookLoginUrl = `${Api.baseUrl}facebook-login/`;
    static gmailLoginUrl = `${Api.baseUrl}gmail-login/`;

    static profileUrl = `${Api.baseUrl}profile/`;
    static walletUrl = `${Api.baseUrl}wallet/`;
    static userWalletUpdateUrl = `${Api.baseUrl}user-update-wallet/`;
    static businessUserUpdateUrl = `${Api.baseUrl}business-user-update/`;
    static paymentHistoryUrl = `${Api.baseUrl}payment-history/`;
    static fileUploadUrl = `${Api.baseUrl}upload/`;
    static followUrl = `${Api.baseUrl}follows/`;
    static followingAdsUrl = `${Api.baseUrl}following-advertises/`;
    static favAdsUrl = `${Api.baseUrl}fav-advertise/`;
    static saveUserUrl = `${Api.baseUrl}save-user/`;
    static categoriesUrl = `${Api.baseUrl}categories/`;
    static statesUrl = `${Api.baseUrl}states/`;
    static citiesUrl = `${Api.baseUrl}city/`;
    static localitiesUrl = `${Api.baseUrl}localities/`;
    static localitiesSearchUrl = `${Api.baseUrl}locality-search/`;

    static attributesUrl = `${Api.baseUrl}attributes/`;
    static catAttributesUrl = `${Api.baseUrl}cat-attributes/`;
    static advertiseListUrl = `${Api.baseUrl}advertise-list/`;
    static propertiesUrl = `${Api.baseUrl}attr-property/`;

    static advertiseUrl = `${Api.baseUrl}advertise/`;

    static chatUrl = `${Api.baseUrl}chat/`;
    static chatListUrl = `${Api.baseUrl}get_user_chat/`;
    static advChatUrl = `${Api.baseUrl}get_adv_chat/`;
    static chatConversations = `${Api.baseUrl}conversations/`;
    static chatUploadUrl = `${Api.baseUrl}chat-upload/`;
    static chatCountUrl = `${Api.baseUrl}chat-count/`;

    static notificationUrl = `${Api.baseUrl}notification/`;

    static extrafeaturesUrl = `${Api.baseUrl}extra-features/`;

    static ordersUrl = `${Api.baseUrl}orders/`;

    static paymentUrl = `${Api.baseUrl}payment/`;

    static businessAdsUrl = `${Api.baseUrl}business-advertises/`;
    static userBusinessAdsUrl = `${Api.baseUrl}user-business-advertises/`;
    static addStoryBusinessAdsUrl = `${Api.baseUrl}business_advertise/`;

    static relatedAdsUrl = `${Api.baseUrl}related-advertises/`;

    static paymentIntentUrl = `${Api.baseUrl}payment-intent/`;

    static readMessageUrl = `${Api.baseUrl}read-message/`;

    static advertiseStatusChangeUrl = `${Api.baseUrl}advertise-status-change/`;
    static basicSettingsUrl = `${Api.baseUrl}basic-settings/`;
    static supportChatUrl = `${Api.baseUrl}support-chat/`;
    static supportCategoryUrl = `${Api.baseUrl}support-category/`;
    static supportReadMsgUrl = `${Api.baseUrl}support-read-message/`;
    static getSupportChatUrl = `${Api.baseUrl}get_support_chat/`;
    static supportChatConsnUrl = `${Api.baseUrl}support-conversations/`;
    static supportAdminUrl = `${Api.baseUrl}support-admins/`;
    static optionsListUrl = `${Api.baseUrl}options-list/`;
    static deleteUser = `${Api.baseUrl}users/`;

    constructor(props) {
        super(props);
        this.state = {};
        this.getBaseUrl = this.getBaseUrl.bind(this);
        this.getImageBaseUrl = this.getImageBaseUrl.bind(this);
    }

    // Get base URL of APIs
    static getBaseUrl() {
        return `https://${
            Api.isStaging
                ? 'zf3a0kdlxb.execute-api.me-south-1.amazonaws.com/dev'
                : 'pp7566y8ui.execute-api.me-south-1.amazonaws.com/production'
        }/api/v1/`;
        // let url = '';
        // switch (Api.environment) {
        //     case 'production':
        //         // url = 'https://dybxqsrmzciun.cloudfront.net/api/v1/'; //serverless url
        //         // url = 'https://api.libanx.com/api/v1/';
        //         url = 'https://pp7566y8ui.execute-api.me-south-1.amazonaws.com/production/api/v1/';
        //         break;
        //     // Default: development server
        //     default:
        //         // url = 'https://d1qmby2c90wdgm.cloudfront.net/api/v1/'; //serverless url
        //         // url = 'https://devapi.libanx.com/api/v1/';
        //         url = 'https://zf3a0kdlxb.execute-api.me-south-1.amazonaws.com/dev/api/v1/';
        //         break;
        // }
        // return url;
    }

    // Get Image base URL of APIs
    static getImageBaseUrl() {
        return `https://libanx.s3.me-south-1.amazonaws.com/${Api.isStaging ? 'stag' : 'prod'}/media/`;
        // return 'https://libanx.s3.me-south-1.amazonaws.com/stag/media/';
        // let url = '';
        // switch (Api.environment) {
        //     case 'production':
        //         url = 'https://libandev-x.s3-us-west-2.amazonaws.com/media/';
        //         break;
        //     // Default: development server
        //     default:
        //         url = 'https://libanxstag.s3-us-west-2.amazonaws.com/media/';
        //         break;
        // }
        // return url;
    }
}
