import customFunctions from './../util/customFunctions';

const init = {
    province: customFunctions.getPROVINCE(),
    district: customFunctions.getDISTRICT(),
    city: customFunctions.getCITY(),
};

const reducer = (state = init, action) => {
    switch (action.type) {
        case 'PROVINCE':
            customFunctions.setPROVINCE(action.province);
            return {
                ...state,
                province: action.province,
            };
        case 'DISTRICT':
            customFunctions.setDISTRICT(action.district);
            return {
                ...state,
                district: action.district,
            };
        case 'CITY':
            customFunctions.setCITY(action.city);
            return {
                ...state,
                city: action.city,
            };
        case 'CLEARLOCATION':
            customFunctions.clearLocationlocalStorage();
            return {
                ...action.init,
            };
        default:
            return state;
    }
};

export default reducer;
