export const isNewChat = chat => ({
    type: 'CHAT',
    chat,
});

export const isNewNotification = notification => ({
    type: 'NOTIFICATION',
    notification,
});

export const setChatCount = chatCount => ({
    type: 'CHATCOUNT',
    chatCount,
});
