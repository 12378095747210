import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Footer from './layouts/layout-footer.component';
import NoFooter from './layouts/layout-nofooter.component';

import asyncComponent from './components/async.component';

const AsyncHome = asyncComponent(() => import('./screens/Home/home.component'));
const AsyncNotFound = asyncComponent(() => import('./screens/NotFound/not-found.component'));
const AsyncAttribute = asyncComponent(() => import('./screens/Attribute/attribute.component'));
const AsyncAds = asyncComponent(() => import('./screens/Ads/ads.component'));
const AsyncAdDetail = asyncComponent(() => import('./screens/AdDetail/addetail.component'));
const AsyncFilter = asyncComponent(() => import('./screens/Filter/filter.component'));
const AsyncSplash = asyncComponent(() => import('./screens/Splash/Splash.component'));
const AsyncLanguageSelect = asyncComponent(() => import('./screens/Language-select/Language-select.component'));
const AsyncSocialSignup = asyncComponent(() => import('./screens/Authentication/SocialSignUp/SocialSignUp.component'));
const AsyncForgotPassword = asyncComponent(() =>
    import('./screens/Authentication/ForgotPassword/ForgotPassword.component'),
);
const AsyncResetCode = asyncComponent(() => import('./screens/Authentication/ResetCode/ResetCode.component'));
const AsyncResetPassword = asyncComponent(() =>
    import('./screens/Authentication/ResetPassword/ResetPassword.component'),
);
const AsyncChangePassword = asyncComponent(() =>
    import('./screens/Authentication/ChangePassword/ChangePassword.component'),
);
const AsyncLogin = asyncComponent(() => import('./screens/Authentication/Login/Login.component'));
const AsyncSignup = asyncComponent(() => import('./screens/Authentication/Signup/Signup.component'));
const AsyncProvince = asyncComponent(() => import('./screens/Location/province.component'));
const AsyncState = asyncComponent(() => import('./screens/Location/state.component'));
const AsyncCity = asyncComponent(() => import('./screens/Location/city.component'));
const AsyncFollowing = asyncComponent(() => import('./screens/Following/following.component'));
const AsyncFollower = asyncComponent(() => import('./screens/Follower/follower.component'));
const AsyncSaved = asyncComponent(() => import('./screens/Saved/Saved.component'));
const AsyncAdpost = asyncComponent(() => import('./screens/AdPost/adpost.component'));
const AsyncCategory = asyncComponent(() => import('./screens/Category/category.component'));
const AsyncAdssubcategory = asyncComponent(() => import('./screens/AdsSubcategory/adssubcategory.component'));
const AsyncAdsattribute = asyncComponent(() => import('./screens/AdsAttribute/adsattribute.component'));
const AsynUserProfile = asyncComponent(() => import('./screens/UserProfile/userprofile.component'));
const AsynContactDetails = asyncComponent(() => import('./screens/ContactDetails/contactdetails.component'));
const AsynEditProfile = asyncComponent(() => import('./screens/UserProfile/editprofile.component'));
const AsynOtherProfile = asyncComponent(() => import('./screens/UserProfile/otherprofile.component'));
const AsynMyAds = asyncComponent(() => import('./screens/MyAds/myads.component'));
const AsynChatNotification = asyncComponent(() => import('./screens/ChatNotification/chatnotification.component'));
const AsynChatSupport = asyncComponent(() => import('./screens/ChatSupport/chatSupport.component'));
const AsynChatSupportDetails = asyncComponent(() =>
    import('./screens/ChatSupportDetail/chatSupportDetail.component.js'),
);
const AsynDurationCost = asyncComponent(() => import('./screens/DurationCost/durationcost.component'));
const AsynLibanXPay = asyncComponent(() => import('./screens/Libanx-pay/Libanx-pay.component'));
const AsynMyWallet = asyncComponent(() => import('./screens/MyWallet/MyWallet.component'));
const AsynTopup = asyncComponent(() => import('./screens/Topup/Topup.component'));
const AsynCreditCardPay = asyncComponent(() => import('./screens/CreditCardPay/CreaditCardPay.component'));
const AsynBusinessSubscription = asyncComponent(() =>
    import('./screens/BusinessSubscription/BusinessSubscription.component'),
);
const AsynBusinessAdPayment = asyncComponent(() =>
    import('./screens/BusinessPaymentPortal/BusinessPaymentPortal.component'),
);
const AsynPaymentHistory = asyncComponent(() => import('./screens/Payment-history/PaymentHistory.component'));
const AsynPaymentDetails = asyncComponent(() => import('./screens/Payment-history/PaymentDetails.component'));
const AsyncAdsListsubcategory = asyncComponent(() => import('./screens/AdListSubcategory/adlistsubcategory.component'));

const AsynPaymentPortal = asyncComponent(() => import('./screens/PaymentPortal/paymentportal.component'));
const AsynAdExtras = asyncComponent(() => import('./screens/AdExtras/adextras.component'));
const AsynChats = asyncComponent(() => import('./screens/ChatDetail/chatdetail.component'));
const AsynAdPreview = asyncComponent(() => import('./screens/AdPreview/adpreview.component'));

// filter location screens
const AsyncFilterProvince = asyncComponent(() => import('./screens/Filter/province.component'));
const AsyncFilterDistrict = asyncComponent(() => import('./screens/Filter/state.component'));
const AsyncFilterCity = asyncComponent(() => import('./screens/Filter/city.component'));
const AsyncFilterCategory = asyncComponent(() => import('./screens/Filter/category.component'));
const AsyncFilterSubcategory = asyncComponent(() => import('./screens/Filter/subcategory.component'));
const AsyncFilterAttribute = asyncComponent(() => import('./screens/Filter/attribute.component'));

const AsyncNotificationDetails = asyncComponent(() =>
    import('./screens/NotificationDetails/notificationdetails.component'),
);

const AsyncUserAds = asyncComponent(() => import('./screens/UserProfile/ads.component'));
const AsyncUserFollower = asyncComponent(() => import('./screens/UserProfile/follower.component'));
const AsyncUserFollowing = asyncComponent(() => import('./screens/UserProfile/following.component'));
// Privacy
const AsyncPrivacy = asyncComponent(() => import('./screens/Privacy/privacy.component'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />
);

const FooterLayout = props => <Footer>{props.children}</Footer>;

const NoFooterLayout = props => <NoFooter>{props.children}</NoFooter>;

// TODO: Consider looping through an object containing all routes
export default ({ childProps }) => {
    return (
        <Switch>
            <AppRoute path="/" exact component={AsyncSplash} props={childProps} layout={NoFooterLayout} />
            <AppRoute path="/login" exact component={AsyncLogin} props={childProps} layout={NoFooterLayout} />
            <AppRoute path="/signup" exact component={AsyncSignup} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/signup-with-social"
                exact
                component={AsyncSocialSignup}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/forgot-password"
                exact
                component={AsyncForgotPassword}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/reset-code" exact component={AsyncResetCode} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/change-password"
                exact
                component={AsyncChangePassword}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute
                path="/reset-password"
                exact
                component={AsyncResetPassword}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/choose-language"
                exact
                component={AsyncLanguageSelect}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/home" exact component={AsyncHome} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/category/:categorySlug"
                exact
                component={AsyncAdsListsubcategory}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute
                path="/attribute/:attributeSlug"
                exact
                component={AsyncAttribute}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute path="/ads/" exact component={AsyncAds} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/ads/:adsSlug/:adsId"
                exact
                component={AsyncAdDetail}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/filter" exact component={AsyncFilter} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/location/province"
                exact
                component={AsyncProvince}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/location/district"
                exact
                component={AsyncState}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/location/city" exact component={AsyncCity} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/ad-post/location/province"
                exact
                component={AsyncProvince}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/ad-post/location/district"
                exact
                component={AsyncState}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/ad-post/location/city"
                exact
                component={AsyncCity}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/ad-post" exact component={AsyncAdpost} props={childProps} layout={NoFooterLayout} />
            <AppRoute path="/following" exact component={AsyncFollowing} props={childProps} layout={FooterLayout} />
            <AppRoute path="/follower" exact component={AsyncFollower} props={childProps} layout={FooterLayout} />
            <AppRoute path="/saved" exact component={AsyncSaved} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/adpost/category"
                exact
                component={AsyncCategory}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/adpost/category/:catSlug"
                exact
                component={AsyncAdssubcategory}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/adpost/attribute/:attributeSlug"
                exact
                component={AsyncAdsattribute}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/profile" exact component={AsynUserProfile} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/contact-details"
                exact
                component={AsynContactDetails}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/profile/edit" exact component={AsynEditProfile} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/ads/:adsSlug/:adsId/profile/:userId"
                exact
                component={AsynOtherProfile}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/profile/myads" exact component={AsynMyAds} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/chat-notification"
                exact
                component={AsynChatNotification}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/chat-support"
                exact
                component={AsynChatSupport}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/profile/myads/promote-ads/:adsId"
                exact
                component={AsynDurationCost}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/libanx-pay" exact component={AsynLibanXPay} props={childProps} layout={FooterLayout} />
            <AppRoute path="/my-wallet" exact component={AsynMyWallet} props={childProps} layout={FooterLayout} />
            <AppRoute
                path="/credit-card-pay"
                exact
                component={AsynCreditCardPay}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/topup" exact component={AsynTopup} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/business/payment"
                exact
                component={AsynBusinessSubscription}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/business/ad/payment"
                exact
                component={AsynBusinessAdPayment}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/payment-history"
                exact
                component={AsynPaymentHistory}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/payment-details/:transactionId"
                exact
                component={AsynPaymentDetails}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/profile/myads/promote-ads/:adsId/payment-portal"
                exact
                component={AsynPaymentPortal}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/adpost/extras" exact component={AsynAdExtras} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/chat-detail/:senderId/:adsId/:threadId"
                exact
                component={AsynChats}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/chat-support-detail/:adminId/:categoryId"
                exact
                component={AsynChatSupportDetails}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/adpost/preview"
                exact
                component={AsynAdPreview}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute path="/ad-post/:adId" exact component={AsyncAdpost} props={childProps} layout={NoFooterLayout} />
            <AppRoute
                path="/filter/province"
                exact
                component={AsyncFilterProvince}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/filter/district"
                exact
                component={AsyncFilterDistrict}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/filter/city"
                exact
                component={AsyncFilterCity}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/notification-detail/:id"
                exact
                component={AsyncNotificationDetails}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute
                path="/filter/category"
                exact
                component={AsyncFilterCategory}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/filter/category/:categorySlug"
                exact
                component={AsyncFilterSubcategory}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/filter/attribute/:attributeSlug"
                exact
                component={AsyncFilterAttribute}
                props={childProps}
                layout={NoFooterLayout}
            />
            <AppRoute
                path="/user-ads/:userId"
                exact
                component={AsyncUserAds}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute
                path="/user-followers/:userId"
                exact
                component={AsyncUserFollower}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute
                path="/user-followings/:userId"
                exact
                component={AsyncUserFollowing}
                props={childProps}
                layout={FooterLayout}
            />
            <AppRoute path="/privacy" exact component={AsyncPrivacy} props={childProps} layout={NoFooterLayout} />

            <AppRoute component={AsyncNotFound} layout={NoFooterLayout} />
        </Switch>
    );
};
